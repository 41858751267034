const getCurrentBrowser = () =>  {
    const ua = window.navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/);
        if (tem !== null) { return { name: 'Opera', version: tem[1] }; }
    }
    if (window.navigator.userAgent.indexOf('Edge') > -1) {
        tem = ua.match(/Edge\/(\d+)/);
        if (tem !== null) { return { name: 'Edge', version: tem[1] }; }
    }
    M = M[2] ? [M[1], M[2]] : [window.navigator.appName, window.navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) !== null) { M.splice(1, 1, tem[1]); }
    return {
        name: M[0],
        version: +M[1]
    };
};

const isBrowserSupported = () => {
    const browser = getCurrentBrowser();

    let supported = false;
    if (browser.name === 'Chrome' && browser.version >= 80) { // before this version, chrome is not supporting ".disconnect" in board component
        supported = true;
    } else if (browser.name === 'Safari' && browser.version >= 13) { //
        supported = true;
    } else if (browser.name === 'Firefox' && browser.version >= 80) {
        supported = true;
    } else if (browser.name === 'Opera' && browser.version >= 70) {
        supported = true;
    } else if (browser.name === 'Edge' && browser.version >= 80) {
        supported = true;
    }
    return supported;
};

export default isBrowserSupported;
