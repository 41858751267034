import {JSXElementConstructor, useMemo, useState} from 'react';

import {TableSortableBody} from '@/components/table/table-sortable-body';
import {TableSortableHead} from '@/components/table/table-sortable-head';
import {TableSortableColumn, TableSorting} from '@/components/table/table-sortable-types';
import ComponentErrorMessage from '@/components/widgets/component-error-message';

type TableSortableProps<DataItem> = {
    TableBodyRowComponent: JSXElementConstructor<{
        data: DataItem;
    }>;
    columns: TableSortableColumn<DataItem>[];
    data: DataItem[];
    defaultSorting?: TableSorting;
}

function sortData<DataItem>(data: DataItem[], columns: TableSortableColumn<DataItem>[], tableSorting: TableSorting) {
    const out = [ ...data ];
    const sortColumn = (columns ?? []).find(c => c.id === tableSorting.id);
    if (!sortColumn) {
        console.warn(`Unknown sort column '${tableSorting.id}. No sorting done`);
        return out;
    }
    if (!sortColumn.compareFn) {
        console.warn(`No compare function set for sort column '${tableSorting.id}. No sorting done`);
        return out;
    }
    if (tableSorting.sortByOrder === 'default') {
        return out;
    }
    else {
        out.sort(sortColumn.compareFn);
        if (tableSorting.sortByOrder === 'desc') {
            out.reverse();
        }
        return out;
    }
}

function TableSortable<DataItem>({ TableBodyRowComponent, columns, data, defaultSorting }: TableSortableProps<DataItem>) {
    const firstSortableColumn = columns?.filter(c => c.sortable)[0] ?? { id: '', sortable: true, label: '' };
    const [tableSorting, setTableSorting] = useState<TableSorting>(defaultSorting ?? {
        id: firstSortableColumn.id,
        sortByOrder: 'default',
    });

    const tableData = useMemo(
        () => {
            return sortData(data ?? [], columns, tableSorting);
        },
        [columns, data, tableSorting]
    );

    if (data && columns && tableSorting) {
        return (
            <table>
                <TableSortableHead columns={columns} tableSorting={tableSorting} onSortingChange={setTableSorting} />
                <TableSortableBody tableData={tableData} TableBodyRowComponent={TableBodyRowComponent} />
            </table>
        );
    }
    else {
        return <ComponentErrorMessage component="TableSortable" />;
    }
}

export {TableSortable};
