import {JSXElementConstructor} from 'react';

import ComponentErrorMessage from '@/components/widgets/component-error-message';

type TableSortableBodyProps<DataItem> = {
    tableData: DataItem[];
    TableBodyRowComponent: JSXElementConstructor<{
        data: DataItem;
    }>;
}

function TableSortableBody<DataItem>({ tableData, TableBodyRowComponent }: TableSortableBodyProps<DataItem>) {
    if (tableData) {
        return (
            <tbody>
                {tableData.map((item, index) => (
                    <TableBodyRowComponent key={`body-entry-${index}`} data={item} />
                ))}
            </tbody>
        );
    }
    else {
        return <ComponentErrorMessage component="TableSortableBody" />;
    }
}

export {TableSortableBody};
