import {LocalStorage} from 'gabi-web-common/util/local-storage';

let newDateUtil__overrideTime;
const persisted_newDateUtil__overrideTime = LocalStorage.get('newDateUtil__overrideTime');
if (persisted_newDateUtil__overrideTime) {
    newDateUtil__overrideTime = new Date(persisted_newDateUtil__overrideTime);
}

export const NewDateUtil = () => {
    if (newDateUtil__overrideTime) {
        return new Date(newDateUtil__overrideTime.getTime());
    }
    return new Date();
};

NewDateUtil.overrideTime = function(date) {
    newDateUtil__overrideTime = date;
    LocalStorage.set('newDateUtil__overrideTime', date ? date.toISOString() : null);
};

NewDateUtil.isOverriden = () => !!newDateUtil__overrideTime;
